/**
 * External dependencies.
 */
import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

/**
 * Internal dependencies.
 */
import UserClient from 'components/user-client/user-client';

import {getMyUsers, getAppMetadataValue} from 'MyUtils';

const useStyles = makeStyles(theme => createStyles({
	modal: {
		width: '500px',
		maxHeight: 'calc(100vh - 60px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		background: '#fff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '4px 8px 16px rgba(17, 17, 17, 0.04)',
		borderRadius: '16px',
		padding: '30px',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 60px)'
		}
	},
	modalHead: {
		position: 'relative',
		padding: '3px 0',
		margin: '0 0 49px',
	},
	modalBody: {
		position: 'relative',
		padding: '0 10px',
		maxHeight: '392px',
		overflowY: 'auto',
		overflowX: 'hidden',
		[theme.breakpoints.down('xs')]: {
			padding: '0',
			maxHeight: '193px',
		},
		'&::-webkit-scrollbar': {
			width: '6px'
		},
		'&::-webkit-scrollbar-track': {
			width: '6px',
			background: '#E6E9EF',
			borderRadius: '20px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '20px',
			background: '#808191',
		}
	},
	modalTitle: {
		fontWeight: '600',
	},
	modalClose: {
		position: 'absolute',
		top: '50%',
		right: '-10px',
		transform: 'translateY(-50%)',
		border: 'none',
		background: '#E6E9EF',
		width: '48px',
		height: '48px',
		borderRadius: '50%',
		'& i': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		}
	},
	modalActions: {
		margin: '35px -30px 0',
		padding: '27px 24px 0',
		borderTop: '1px solid #E6E9EF',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			display: 'block',

		}
	},
	modalButton: {
		flex: '0 0 calc(50% - 30px)',
		maxWidth: '50%',
		minWidth: '218px',
		margin: '0 !important',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			width: '100%',
			minWidth: '0',
		},
		'& + .MuiButtonBase-root': {
			[theme.breakpoints.down('xs')]: {
				marginTop: '20px !important'
			}
		}
	},
	sectionText: {
		fontSize: '20px',
		fontWeight: '500',
		lineHeight: '1.65',
		letterSpacing: '0',
		[theme.breakpoints.down('xs')]: {
			fontSize: '11px',
			padding: '0 20px',
		}
	},
}));

const ModalClients = ({ onClose, modifier, creatorId }) => {
	const classes = useStyles();
	const [users, setUsers] = useState(() => {
		let aux = [];
		for (let i = 0; i < 6; i++) {
			aux.push({
				avatar: "loading.jpg",
				name: "loading",
				skeleton: true,
			});
		}
		return aux;
	});


	useEffect(() => {
		async function fetchUsers() {
			const data = await getMyUsers(creatorId, false, true);

			//console.log(data)

			let auxUsers = [];
			setUsers([]);
			data.forEach(element => {
				//console.log(element);
				const name = (getAppMetadataValue(element, "Role") == "Client") ? element.username : element.username + " ✪"; 
				/* if (element.user_id != userId) { */

				const userObject = {
					name: name,
					avatar: element.picture,
					user_id: element.user_id,
					skeleton: false,
				};

				auxUsers.push(userObject);
			});
			auxUsers.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase())
			setUsers(auxUsers);
		};

		fetchUsers();
	}, [])

	if (users.length > 0) {
		return (
			<Box className={classes.modal + ' ' + modifier}>
				<Box className={classes.modalHead}>
					<button className={classes.modalClose} onClick={onClose}>
						<i className="ico-x"></i>
					</button>

					<Typography
						variant="h4"
						component="h4"
						className={classes.modalTitle}
					>Clients</Typography>
				</Box>

				<Box className={classes.modalBody}>
					{users?.map((user, index) => {
						return (
							<UserClient
								key={index}
								name={user.name}
								avatar={user.avatar}
								userId={user.user_id}
								skeleton={user.skeleton}
							/>
						)
					})}
				</Box>
			</Box>
		);
	} else {
		return (
			<Box className={classes.modal + ' ' + modifier}>
				<Box className={classes.modalHead}>
					<button className={classes.modalClose} onClick={onClose}>
						<i className="ico-x"></i>
					</button>

					<Typography
						variant="h4"
						component="h4"
						className={classes.modalTitle}
					>Clients</Typography>
				</Box>

				<Box className={classes.modalBody}>
					<Typography
						component="p"
						className={classes.sectionText}
					>This administrator has not registered any clients</Typography>
				</Box>
			</Box>
		)
	}
};

export default ModalClients;