/**
 * External dependencies.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => createStyles({
	box: {
		position: 'relative',
		paddingTop: '132%',
		borderRadius: '15px',
		filter: 'drop-shadow(-8px -16px 24px rgba(227, 230, 236, 0.5)) drop-shadow(16px 8px 24px rgba(227, 230, 236, 0.5))',
	},
	boxImage: {
		position: 'absolute',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		borderRadius: '15px',
	},
	boxButton: {
		position: 'absolute',
		bottom: '-5px',
		right: '1px',
		padding: '0'
	}
}));

const WidgetPhoto = ( { modifier, image, status } ) => {
	const classes = useStyles();

	return (
		<Box className={classes.box + ' ' + modifier}>
			<img src={image} alt="" className={classes.boxImage} />

			<IconButton aria-label="edit" className={classes.boxButton}>
				{status === 'success' ? (
					<i className="ico-success" />
				) : (
					<i className="ico-x-red" />
				)}
			</IconButton>
		</Box>
	);
};

export default WidgetPhoto;