/**
 * External dependencies.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => createStyles({
	box: {
		position: 'relative',
		height: '284px',
		borderRadius: '16px',
		border: '2px dashed #fff',
		background: 'linear-gradient(#fff,#fff) padding-box , linear-gradient(to right, #0C55ED, #00CCEE 49%) border-box',
		[theme.breakpoints.down('xs')]: {
			height: '200px',
		},
		'& .MuiLinearProgress-root': {
			height: '8px',
			borderRadius: '8px',
			backgroundColor: '#E6E9EF',
		},
		'& .MuiLinearProgress-bar': {
			borderRadius: '8px',
			background: 'radial-gradient(100% 100% at 0% 50%, #0C55ED 0%, #00CCEE 100%)',
		}
	},
	boxText: {
		fontWeight: '500 !important',
		fontSize: '16px !important',
		color: '#808191',
		textAlign: 'right',
		marginBottom: '4px !important',
	},
	boxMessage: {
		maxWidth: '260px',
		margin: '10px auto 0 !important',
		fontWeight: '500 !important',
		fontSize: '16px !important',
		color: '#808191',
		textAlign: 'center'
	},
	boxInner: {
		position: 'absolute',
		top: '50%',
		left: '45px',
		right: '45px',
		transform: 'translateY(-50%)',
		marginTop: '31px',
		[theme.breakpoints.down('xs')]: {
			left: '10px',
			right: '10px',
		},
		'& [class^="ico-"]': {
			position: 'absolute',
			top: '-55px',
			left: '50%',
			transform: 'translateX(-50%)',
		},
		'& .ico-success': {
			width: '48px',
			height: '48px',
		},
		'& .ico-x-red': {
			width: '48px',
			height: '48px',
		}
	}
}));

const WidgetLoad = ( { modifier, status, progress } ) => {
	const classes = useStyles();

	return (
		<Box className={classes.box + ' ' + modifier}>
			<Box className={classes.boxInner}>
				{status === 'loading' && (
					<i className="ico-load"></i>
				)}

				{status === 'success' && (
					<i className="ico-success"></i>
				)}

				{status === 'error' && (
					<Box>
						<i className="ico-x-red"></i>
						
						<Typography variant="h4" component="h4" className={classes.boxMessage}>
							La fotografía no es adecuada, prueba a subir una diferente.
						</Typography>
					</Box>
				)}

				{status !== 'error' && (
					<Box className={classes.boxInner}>
						<Typography variant="h4" component="h4" className={classes.boxText}>{progress}%</Typography>

						<LinearProgress variant="determinate" value={progress} />
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default WidgetLoad;