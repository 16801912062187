/**
 * External dependencies.
 */
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

import ModalMediaNew from 'components/modal-media-new/modal-media-new';
import { AskForFile } from 'MyUtils';

const useStyles = makeStyles(theme => createStyles({
	box: {
		position: 'relative',
		background: '#fff',
		boxShadow: '-8px -16px 24px rgba(227, 230, 236, 0.5), 16px 8px 24px rgba(227, 230, 236, 0.5)',
		borderRadius: '16px',
		padding: '24px 26px',
		[theme.breakpoints.down('xs')]: {
		},
		[theme.breakpoints.down('xs')]: {
		}
	},
	boxBody: {
		marginRight: '42px',
		borderRadius: '30px',
		border: '2px dashed #fff',
		background: 'linear-gradient(#fff,#fff) padding-box , linear-gradient(to right, #0C55ED, #00CCEE 49%) border-box',
		padding: '43px 10px',
		[theme.breakpoints.down('md')]: {
			marginRight: '20px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0 0 30px',
		},
		[theme.breakpoints.down('xs')]: {
			
		}
	},
	boxBodyContent: {
		textAlign: 'center',
		maxWidth: '380px',
		margin: 'auto',
		'& h4': {
			background: 'radial-gradient(100% 100% at 0% 50%, #0C55ED 0%, #00CCEE 100%)',
			WebkitBackgroundClip: 'text !important',
			textFillColor: 'transparent',
			fontWeight: '600',
			fontSize: '25px',
			lineHeight: '1.52',
			marginBottom: '28px',
			[theme.breakpoints.down('xs')]: {
				fontSize: '18px',
			}
		}
	},
	boxButton: {
		minWidth: '228px',
		[theme.breakpoints.down('xs')]: {
			minWidth: '170px',
		}
	},
	boxContent: {
		padding: '16px 0 0 17px',
		[theme.breakpoints.down('md')]: {
			padding: '0',
		},
		'& h4': {
			fontWeight: '600',
			marginBottom: '14px',
		},
		'& p': {
			marginBottom: '15px',
			[theme.breakpoints.down('md')]: {
				fontSize: '16px'
			}
		},
		'& ul': {
			listStyle: 'none',
			marginBottom: '-14px',
			[theme.breakpoints.down('md')]: {
				fontSize: '16px'
			}
		},
		'& ul li': {
			position: 'relative',
			paddingLeft: '28px',
			marginBottom: '14px',
			'&:after': {
				content: '""',
				position: 'absolute',
				top: '10px',
				left: '12px',
				width: '4px',
				height: '4px',
				borderRadius: '50%',
				background: '#323338',
			}
		}
	}
}));

const WidgetInfo = ( { modifier } ) => {
	const classes = useStyles();
	const [ openInnerModal, setOpenInnerModal ] = useState(false);
	const [ newFile, setNewFile ] = useState({});

	return (
		<Box className={classes.box + ' ' + modifier}>
			<Grid container>
				<Grid item md={8} sm={12}>
					<Box className={classes.boxBody}>
						<Box className={classes.boxBodyContent}>
							<Typography variant="h4" component="h4" className={classes.boxTitle}>
								{/* Suelta */}Selecciona aquí tus archivos para
								añadirlos a la librería
							</Typography>

							<Button 
								variant="contained" 
								component="label"
								color="secondary" 
								className={classes.boxButton}
							>
								Subir archivos
								<input type="file" accept='.png, .jpg, .mp4, .mov, .pdf' onChange={event => {
									const selectedFile = AskForFile(event);
									if (selectedFile) {
										setNewFile(selectedFile)
										setOpenInnerModal(!openInnerModal)
									}
								}} hidden />
							</Button>
						</Box>
					</Box>
				</Grid>

				<Grid item md={4} sm={12}>
					<Box className={classes.boxContent}>
						<Typography variant="h4" component="h4">Formatos soportados</Typography>
						
						<Typography variant="body2">
							Para ver tus archivos correctamente en el entorno virtual la relación de aspecto debe ser de 16:9. Los nombres no pueden contener símbolos.
						</Typography>

						<ul>
							<li><strong>Imágenes</strong> en .JPG o .PNG</li>
							
							<li><strong>Vídeos</strong> en .MP4 o .MOV (H264)</li>
							
							<li><strong>Presentaciones</strong> en .PDF</li>
						</ul>

					</Box>
				</Grid>
			</Grid>

			<Modal
				open={openInnerModal}
				onClose={(e) => { setOpenInnerModal(false) }}
			>
				<div><ModalMediaNew file={newFile} title={newFile.name} onClose={(e) => { setOpenInnerModal(false) }}/></div>
			</Modal>
		</Box>
	);
};

export default WidgetInfo;