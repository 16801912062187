/**
 * External dependencies.
 */
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Modal from '@mui/material/Modal';

/**
 * Internal dependencies.
 */
import LayoutAlt from 'layouts/layout-alt/layout-alt';

import ModalDelete from 'components/modal-delete/modal-delete';
import ModalDeleteUser from 'components/modal-delete-user/modal-delete-user';

import ModalEdit from 'components/modal-edit/modal-edit';
import ModalEditUser from 'components/modal-edit-user/modal-edit-user';

import ModalGuests from 'components/modal-guests/modal-guests';
import ModalInvite from 'components/modal-clients/modal-clients';

import ModalNew from 'components/modal-new/modal-new';
import ModalNewUser from 'components/modal-new-user/modal-new-user';

import ModalAvatar from 'components/modal-avatar/modal-avatar';
import ModalAvatarLoading from 'components/modal-avatar-loading/modal-avatar-loading';
import ModalAvatarSuccess from 'components/modal-avatar-success/modal-avatar-success';
import ModalAvatarError from 'components/modal-avatar-error/modal-avatar-error';
import ModalAvatarEdit from 'components/modal-avatar-edit/modal-avatar-edit';

import ModalMediaLoading from 'components/modal-media-loading/modal-media-loading';
import ModalMediaSuccess from 'components/modal-media-success/modal-media-success';
import ModalMediaNew from 'components/modal-media-new/modal-media-new';
import ModalMediaEdit from 'components/modal-media-edit/modal-media-edit';
import ModalDeleteMedia from 'components/modal-delete-media/modal-delete-media';

const useStyles = makeStyles(theme => createStyles({
	section: {
		position: 'relative',
		zIndex: '10',
		height: 'calc(100vh - 64px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '129px 0 35px',
		[theme.breakpoints.down('md')]: {
			padding: '150px 0 70px',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '100px 0 70px',
		},
	},
	list: {
		maxWidth: '300px',
		'& li': {
			marginBottom: '20px'
		}
	}


}));


const Popups = () => {
	const classes = useStyles();

	const [openModal1, setOpenModal1] = useState(false);
	const [openModal2, setOpenModal2] = useState(false);
	const [openModal3, setOpenModal3] = useState(false);
	const [openModal4, setOpenModal4] = useState(false);
	const [openModal5, setOpenModal5] = useState(false);
	const [openModal6, setOpenModal6] = useState(false);
	const [openModal7, setOpenModal7] = useState(false);
	const [openModal8, setOpenModal8] = useState(false);
	const [openModal9, setOpenModal9] = useState(false);
	const [openModal10, setOpenModal10] = useState(false);
	const [openModal11, setOpenModal11] = useState(false);
	const [openModal12, setOpenModal12] = useState(false);
	const [openModal13, setOpenModal13] = useState(false);
	const [openModal14, setOpenModal14] = useState(false);
	const [openModal15, setOpenModal15] = useState(false);
	const [openModal16, setOpenModal16] = useState(false);
	const [openModal17, setOpenModal17] = useState(false);
	const [openModal18, setOpenModal18] = useState(false);

	return (
		<LayoutAlt activeNav="users">
			<Box className={classes.section}>
				<Box>
					<Container maxWidth="xl">
						<List className={classes.list}>
							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal1(!openModal1) }}
								>
									Popup 1 Modal Delete
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal2(!openModal2) }}
								>
									Popup 2 Modal Delete User
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal3(!openModal3) }}
								>
									Popup 3 Modal Edit
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal4(!openModal4) }}
								>
									Popup 4 Modal Edit User
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal5(!openModal5) }}
								>
									Popup 5 Modal Guests
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal6(!openModal6) }}
								>
									Popup 6 Modal Invite
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal7(!openModal7) }}
								>
									Popup 7 Modal New
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal8(!openModal8) }}
								>
									Popup 8 Modal New User
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal9(!openModal9) }}
								>
									Popup 9 Modal Avatar
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal10(!openModal10) }}
								>
									Popup 10 Modal Avatar Loading
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal11(!openModal11) }}
								>
									Popup 11 Modal Avatar Success
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal12(!openModal12) }}
								>
									Popup 12 Modal Avatar Error
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal13(!openModal13) }}
								>
									Popup 13 Modal Avatar Error
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal14(!openModal14) }}
								>
									Popup 14 Modal Media Loading
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal15(!openModal15) }}
								>
									Popup 15 Modal Media Success
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal16(!openModal16) }}
								>
									Popup 16 Modal Media New
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal17(!openModal17) }}
								>
									Popup 17 Modal Media Edit
								</Button>
							</ListItem>

							<ListItem>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => { setOpenModal18(!openModal18) }}
								>
									Popup 18 Modal Delete Media
								</Button>
							</ListItem>
						</List>
					</Container>
				</Box>
			</Box>

			<Modal
				open={openModal1}
				onClose={(e) => { setOpenModal1(false) }}
			>
				<div><ModalDelete onClose={(e) => { setOpenModal1(false) }} /></div>
			</Modal>

			<Modal
				open={openModal2}
				onClose={(e) => { setOpenModal2(false) }}
			>
				<div><ModalDeleteUser onClose={(e) => { setOpenModal2(false) }} /></div>
			</Modal>

			<Modal
				open={openModal3}
				onClose={(e) => { setOpenModal3(false) }}
			>
				<div><ModalEdit onClose={(e) => { setOpenModal3(false) }} /></div>
			</Modal>

			<Modal
				open={openModal4}
				onClose={(e) => { setOpenModal4(false) }}
			>
				<div><ModalEditUser onClose={(e) => { setOpenModal4(false) }} /></div>
			</Modal>

			<Modal
				open={openModal5}
				onClose={(e) => { setOpenModal5(false) }}
			>
				<div><ModalGuests onClose={(e) => { setOpenModal5(false) }} /></div>
			</Modal>

			<Modal
				open={openModal6}
				onClose={(e) => { setOpenModal6(false) }}
			>
				<div><ModalInvite onClose={(e) => { setOpenModal6(false) }} /></div>
			</Modal>

			<Modal
				open={openModal7}
				onClose={(e) => { setOpenModal7(false) }}
			>
				<div><ModalNew onClose={(e) => { setOpenModal7(false) }} /></div>
			</Modal>

			<Modal
				open={openModal8}
				onClose={(e) => { setOpenModal8(false) }}
			>
				<div><ModalNewUser onClose={(e) => { setOpenModal8(false) }} /></div>
			</Modal>

			<Modal
				open={openModal9}
				onClose={(e) => { setOpenModal9(false) }}
			>
				<div><ModalAvatar onClose={(e) => { setOpenModal9(false) }} /></div>
			</Modal>

			<Modal
				open={openModal10}
				onClose={(e) => { setOpenModal10(false) }}
			>
				<div><ModalAvatarLoading onClose={(e) => { setOpenModal10(false) }} /></div>
			</Modal>

			<Modal
				open={openModal11}
				onClose={(e) => { setOpenModal11(false) }}
			>
				<div><ModalAvatarSuccess onClose={(e) => { setOpenModal11(false) }} /></div>
			</Modal>

			<Modal
				open={openModal12}
				onClose={(e) => { setOpenModal12(false) }}
			>
				<div><ModalAvatarError onClose={(e) => { setOpenModal12(false) }} /></div>
			</Modal>

			<Modal
				open={openModal13}
				onClose={(e) => { setOpenModal13(false) }}
			>
				<div><ModalAvatarEdit onClose={(e) => { setOpenModal13(false) }} /></div>
			</Modal>

			<Modal
				open={openModal14}
				onClose={(e) => { setOpenModal14(false) }}
			>
				<div><ModalMediaLoading onClose={(e) => { setOpenModal14(false) }} /></div>
			</Modal>

			<Modal
				open={openModal15}
				onClose={(e) => { setOpenModal15(false) }}
			>
				<div><ModalMediaSuccess onClose={(e) => { setOpenModal15(false) }} /></div>
			</Modal>

			<Modal
				open={openModal16}
				onClose={(e) => { setOpenModal16(false) }}
			>
				<div><ModalMediaNew onClose={(e) => { setOpenModal16(false) }} /></div>
			</Modal>

			<Modal
				open={openModal17}
				onClose={(e) => { setOpenModal17(false) }}
			>
				<div><ModalMediaEdit onClose={(e) => { setOpenModal17(false) }} /></div>
			</Modal>

			<Modal
				open={openModal18}
				onClose={(e) => { setOpenModal18(false) }}
			>
				<div><ModalDeleteMedia onClose={(e) => { setOpenModal18(false) }} /></div>
			</Modal>


		</LayoutAlt>
	);
};

export default Popups;
