/**
 * External dependencies.
 */
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Pagination from '@mui/material/Pagination';
import Modal from '@mui/material/Modal';

/**
 * Internal dependencies.
 */
import LayoutAlt from 'layouts/layout-alt/layout-alt';
import Article from 'components/article/article';
import ModalNew from 'components/modal-new/modal-new';

const useStyles = makeStyles(theme => createStyles({
	section: {
		position: 'relative',
		zIndex: '10',
		height: 'calc(100vh - 64px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '129px 0 35px',
		[theme.breakpoints.down('md')]: {
			padding: '150px 0 70px',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '54px 0 70px',
		},
	},
	sectionBar: {
		textAlign: 'center',
		marginBottom: '70px',
		[theme.breakpoints.down('xs')]: {
			position: 'fixed',
			bottom: '97px',
			right: '19px',
			marginBottom: '0',
			zIndex: '100',
		}
	},
	articles: {
		margin: '0 -77px',
		[theme.breakpoints.down('lg')]: {
			margin: '0',
		},
		[theme.breakpoints.down('xs')]: {
			margin: '0 -15px',
		}
	},
	articlesActions: {
		marginBottom: '10px',
		paddingLeft: '117px',
		[theme.breakpoints.down('xs')]: {
			paddingLeft: '40px',
			marginBottom: '8px',
		},
		'& button': {
			[theme.breakpoints.down('xs')]: {
				fontSize: '12px',
			},
			'& i': {
				[theme.breakpoints.down('xs')]: {
					width: '10px',
					height: '10px',
				}	
			}
		}
	},
	sectionPagination: {
		marginTop: '90px',
		[theme.breakpoints.down('xs')]: {
			margin: '90px -15px 0'
		},
		'& .MuiPagination-ul': {
			justifyContent: 'center'
		},
		'& .MuiButtonBase-root': {
			width: '34px',
			height: '34px',
			lineHeight: '34px',
			fontSize: '16px',
			border: 'none',
			background: '#fff',
			margin: '0 5px',
		},
		'& Mui-disabled': {
			background: '#fff'
		},
		'& .Mui-selected': {
			backgroundColor: '#00CCEE !important',
			color: '#fff',
			borderRadius: '50%',
		},
		'& li:last-child': {
			'& button': {
				background: 'none'
			}
		}
	},
	sectionButton: {
		[theme.breakpoints.down('xs')]: {
			fontSize: '0',
			minWidth: '0',
			width: '56px',
			height: '56px',
			padding: '0',
			borderRadius: '50%',
		},
		'& i': {
			display: 'none',
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			[theme.breakpoints.down('xs')]: {
				display: 'inline-block',
			},
		}

	}


}));

const articles = [
	{
		image: require('assets/images/a0.png'),
		name: "Demo 1",
		avatars: [ 
			{	
				name: "Avatar Name",
				image: require('assets/images/av1.jpg')
			}
		]
	},
	{
		image: require('assets/images/a1.png'),
		name: "Demo Endesa",
		avatars: [ 
			{	
				name: "Avatar Name",
				image: require('assets/images/av2.jpg')
			},
			{
				name: "Oo Pp",
				image: ""
			},
			{
				name: "Avatar Image",
				image: require('assets/images/av3.jpg')
			},
			{	
				name: "Avatar Name",
				image: require('assets/images/av2.jpg')
			},
			{
				name: "Oo Pp",
				image: ""
			},
			{
				name: "Avatar Image",
				image: require('assets/images/av3.jpg')
			}
		]
	},
	{
		image: require('assets/images/a2.png'),
		name: "Demo Naturgy",
		avatars: [ 
			{	
				name: "Avatar Name",
				image: require('assets/images/av2.jpg')
			},
			{
				name: "Avatar Image",
				image: require('assets/images/av1.jpg')
			},
			{
				name: "Avatar Image",
				image: require('assets/images/av4.jpg')
			},
			{
				name: "Avatar Image",
				image: require('assets/images/av3.jpg')
			}
		]
	},
	{
		image: require('assets/images/a3.png'),
		name: "Sala de reuniones",
		avatars: [ 
			{	
				name: "Avatar Name",
				image: require('assets/images/av1.jpg')
			},
			{
				name: "Avatar Image",
				image: require('assets/images/av3.jpg')
			}
		]
	},
	{
		image: require('assets/images/a0.png'),
		name: "Sala prueba",
		avatars: [ 
			{	
				name: "Avatar Name",
				image: require('assets/images/av5.jpg')
			}
		]
	}
]

const CirclesCover = () => {
	const classes = useStyles();
	const [openModal, setOpenModal] = useState(false);

	return (
		<LayoutAlt activeNav="circles">
			<Box className={classes.section}>
				<Box>
					<Container maxWidth="xl">
						<Box className={classes.sectionBar}>
							<Button 
								variant="contained" 
								color="secondary" 
								className={classes.sectionButton}
								onClick={(e) => { 
									e.preventDefault()

									setOpenModal(!openModal) 
								}}
							>
								New circle

								<i className="ico-plus"></i>
							</Button>
						</Box>

						<Box className={classes.articles}>
							<Box className={classes.articlesActions}>
								<Button variant="text">Name <i className="ico-arrow-up"></i></Button>
							</Box>

							{articles?.map((article, index) => {
								return (
									<Article
										key={index}
										image={article.image}
										name={article.name}
										avatars={article.avatars}
									/>
								) 
							})}
						</Box>

						<Pagination 
							count={5} 
							variant="outlined" 
							shape="rounded" 
							className={classes.sectionPagination}
						/>
						
						
					</Container>
				</Box>
			</Box>
			
			<Modal
				open={openModal}
				onClose={(e) => { setOpenModal(false) }}
			>
				<div><ModalNew onClose={(e) => { setOpenModal(false) }} /></div>
			</Modal>
		</LayoutAlt>
	);
};

export default CirclesCover;
