/**
 * External dependencies.
 */
import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';

/**
 * Internal dependencies.
 */
import Logo from 'components/logo/logo';
import LogoImage from 'assets/images/logo.svg';

import { requireAuthToken } from 'MyUtils';

const Header = () => {
	useEffect(() => {
		async function rememberAuth() {
			const authToken = await requireAuthToken(true, false);

			if (authToken != "") window.location.replace("/circles");
		};

		rememberAuth();
	}, []);

	return (
		<AppBar position="absolute">
			<Container maxWidth={false}>
				<Logo>
					<img src={LogoImage} alt="logo" />
				</Logo>
			</Container>
		</AppBar>
	);
};

export default Header;
