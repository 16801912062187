/**
 * External dependencies.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => createStyles({
	modal: {
		width: '500px',
		maxHeight: 'calc(100vh - 60px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		background: '#fff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '4px 8px 16px rgba(17, 17, 17, 0.04)',
		borderRadius: '16px',
		padding: '30px',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 60px)'
		}
	},
	modalHead: {
		position: 'relative',
		padding: '3px 0',
		margin: '0 0 38px',
	},
	modalContent: {
		padding: '0 80px',
		textAlign: 'center',
		[theme.breakpoints.down('xs')]: {
			padding: '0',
			maxHeight: '193px',
		}
	},
	modalText: {
		fontWeight: '600',
		fontSize: '24px',
		lineHeight: '1.42',
		letterSpacing: '0.5px',
		'& span': {
			color: '#00CCEE'
		}
	},
	modalTitle: {
		fontWeight: '600',
	},
	modalClose: {
		position: 'absolute',
		top: '50%',
		right: '-10px',
		transform: 'translateY(-50%)',
		border: 'none',
		background: '#E6E9EF',
		width: '48px',
		height: '48px',
		borderRadius: '50%',
		'& i': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		}
	},
	modalActions: {
		margin: '30px -30px 0',
		padding: '27px 24px 0',
		borderTop: '1px solid #E6E9EF',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			display: 'block',

		}
	},
	modalButton: {
		flex: '0 0 calc(50% - 30px)',
		maxWidth: '50%',
		minWidth: '218px',
		margin: '0 !important',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			width: '100%',
			minWidth: '0',
		},
		'& + .MuiButtonBase-root': {
			[theme.breakpoints.down('xs')]: {
				marginTop: '20px !important'
			}
		}
	}
}));

const ModalGenericAlert = ( { onClose, modifier, title, children } ) => {
	const classes = useStyles();

	return (
		<Box className={classes.modal + ' ' + modifier}>
			<Box className={classes.modalHead}>
				<button className={classes.modalClose} onClick={onClose}>
					<i className="ico-x"></i>
				</button>

				<Typography 
					variant="h4" 
					component="h4"
					className={classes.modalTitle}
				>{title}</Typography>
			</Box>

			<Box className={classes.modalContent}>
				<Typography 
					variant="h4" 
					component="h4"
					className={classes.modalText}
				>
					{children}
				</Typography>
			</Box>

			{/* <Box className={classes.modalActions}>
				<Button 
					variant="contained" 
					color="secondary"
					className={classes.modalButton}
					onClick={onClose}
				>Cerrar</Button>
			</Box> */}
		</Box>
	);
};

export default ModalGenericAlert;