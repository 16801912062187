/**
 * Internal dependencies
 */
import Routes from 'blocks/routes/routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const  App = () => {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
		<Routes />
	  </LocalizationProvider>
	);
}

export default App;
