/**
 * External dependencies.
 */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import PhenomImg from 'assets/images/pl_Phenom.png';
import LatitudeImg from 'assets/images/pl_Latitude.png';
import Global6000Img from 'assets/images/pl_Global6000.png';
import CH350Img from 'assets/images/pl_CH350.png';
import CH650Img from 'assets/images/pl_CH650.png';

const useStyles = makeStyles(theme => createStyles({
	card: {
		borderRadius: '32px',
		overflow: 'hidden',
		boxShadow: '-8px -16px 24px rgba(227, 230, 236, 0.5), 16px 8px 24px rgba(227, 230, 236, 0.5)',
		marginBottom: '20px',
	},
	cardContent: {
		padding: '29px 38px 31px !important'
	},
	cardTitle: {
		fontWeight: '600',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		textAlign: 'left',
		paddingLeft:'1em',
		paddingTop:'1em',
	},
	planeName: {
		fontWeight: '500',
		color: '#808191',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		textAlign: 'center'
	},
	cardButton: {
		maxWidth: '122px',
		padding: '6px 10px',
	},
	skeletonImage: {
		height: '186px !important'
	},
	modalActions: {
		margin: '28px -30px 0',
		padding: '27px',
		paddingLeft: '10%',
		paddingRight: '10%',
		borderTop: '1px solid #E6E9EF',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	modalButton: {
		flex: '0 0 calc(50% - 30px)',
		maxWidth: '25%',
		minWidth: '218px',
		margin: '0 !important',
		'& + .MuiButtonBase-root': {
			[theme.breakpoints.down('xs')]: {
				marginTop: '20px !important'
			}
		}
	},
	canvasContainer: {
		height: '40vh',
		width: '100%',
	},
	canvas: {
		width: '100%',
		height: '100%',
	},

}));

const Heatmap = ({ image, title, data, PlaneIDs, AreaIDs }) => {
	const classes = useStyles();
	const canvasRef = useRef(null);
	const canvasContainerRef = useRef(null);
	const canvasWidth = 1544;
	const canvasHeight = 648;
	const [ currentImageIndex, setCurrentImageIndex ] = useState(0);

	let imageData = [
		{
			imagePath: PhenomImg,
			areas: [
				{ x: 500, y: 293, width: 1088, height: 373 }, // Area 1 Cabin
				{ x: 0, y: 293, width: 500, height: 373 }, // Area 2 Cockpit
				{ x: 1588, y: 293, width: 133, height: 373 }, // Area 3 Lavatory
				{ x: 1767, y: 293, width: 281, height: 373 }, // Area 4 Baggage
			],
			colors: []
		}, 
		{
			imagePath: LatitudeImg,
			areas: [
				{ x: 496, y: 302, width: 945, height: 357 }, // Area 1 Cabin
				{ x: 19, y: 302, width: 477, height: 357 }, // Area 2 Cockpit
				{ x: 1442, y: 302, width: 210, height: 357 }, // Area 3 Lavatory
				{ x: 1675, y: 302, width: 373, height: 357 }, // Area 4 Baggage
			],
			colors: []
		}, 
		{
			imagePath: Global6000Img,
			areas: [
				{ x: 395, y: 328, width: 1045, height: 309 }, // Area 1 Cabin
				{ x: 21, y: 328, width: 374, height: 309 }, // Area 2 Cockpit
				{ x: 1741, y: 328, width: 122, height: 309 }, // Area 3 Lavatory
				{ x: 1863, y: 328, width: 184, height: 309 }, // Area 4 Baggage
				{ x: 1440, y: 328, width: 301, height: 309 }, // Area 5 Private room
			],
			colors: []
		},
		{
			imagePath: CH350Img,
			areas: [
				{ x: 539, y: 276, width: 1153, height: 407}, // Area 1 Cabin
				{ x: 0, y: 276, width: 540, height: 407}, // Area 2 Cockpit
				{ x: 1692, y: 276, width: 172, height: 407}, // Area 3 Lavatory
				{ x: 1864, y: 276, width: 184, height: 407}, // Area 4 Baggage
			],
			colors: []
		},
		{
			imagePath: CH650Img,
			areas: [
				{ x: 572, y: 261, width: 1046, height: 438}, // Area 1 Cabin
				{ x: 0, y: 261, width: 572, height: 438}, // Area 2 Cockpit
				{ x: 1618, y: 261, width: 218, height: 438}, // Area 3 Lavatory
				{ x: 1836, y: 261, width: 213, height: 438}, // Area 4 Baggage
			],
			colors: []
		},
	];

	function calcAverage(data) {
		/**
		* Structure:
		* [
		* 	{total: [ , , , ], count: [ , , , ]}
		* ] */

		let arrayOfData = new Array(PlaneIDs.length);

		for (let i = 0; i < arrayOfData.length; i++) {
			arrayOfData[i] = {
				total: new Array(AreaIDs.length).fill(0),
				count: new Array(AreaIDs.length).fill(0),
			}
		}

		data.forEach(visit => {
			visit.areas.forEach(area => {
				arrayOfData[visit.planeId].total[area.areaId] += area.timeSpent;
				arrayOfData[visit.planeId].count[area.areaId]++;
			})
		});

		// Two-dimensional array storing the average spent time for each area in each plane
		return arrayOfData.map(plane => plane.total.map((totalTime, index) => {
			const count = plane.count[index];
			if (count !== 0) {
				return totalTime / count;
			} else {
				return 0;
			}
		}));
	}

	function interpolate(value1, value2, ratio) {
		return value1 * (1 - ratio) + value2 * ratio;
	}

	function generateImageColors(averages) {
		var normalizedAverages = averages.map(row => {
			var maxValue = Math.max(...row); // Find the maximuim value in the row array

			if (maxValue !== 0) {
				return row.map(value => value / maxValue);
			  } else {
				return row.map(() => 0);
			  }
		});

		imageData.forEach((plane, index) => {
			plane.colors = normalizedAverages[index].map(normalizedAverage => `hsla(${interpolate(240, 0, normalizedAverage)}, 100%, 50%, 0.5)`);
		});

		//console.log(imageData);
	}

	function loadNextImage() {
		//console.log("loadNextImage");
		if (currentImageIndex < imageData.length - 1)
			setCurrentImageIndex(currentImageIndex + 1);
	}
	
	function loadPreviousImage() {
		//console.log("loadPreviousImage");
		if (currentImageIndex > 0)
			setCurrentImageIndex(currentImageIndex - 1);
	}

	let mutex = false; 
	function loadCurrentImage() {
		if (mutex) {
			//console.log("Mutex, returning");
			return;
		}
		if (!data || data.length == 0) return;
		mutex = true;

		const averages = calcAverage(data);
		//console.log(averages);
		generateImageColors(averages.map(innerArray => innerArray.slice(1))); // Discard first element which is for the hangar

		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");

		const { imagePath, areas, colors } = imageData[currentImageIndex];

		const image = new Image();
		image.src = imagePath;

		image.onload = function () {
			// Fit the image within the canvas size
			const imageAspectRatio = image.width / image.height;
			const canvasAspectRatio = canvas.width / canvas.height;
			let scaleRatio = 1;

			if (imageAspectRatio > canvasAspectRatio) {
				scaleRatio = canvas.width / image.width;
			} else {
				scaleRatio = canvas.height / image.height;
			}

			const scaledWidth = image.width * scaleRatio;
			const scaledHeight = image.height * scaleRatio;
			const offsetX = (canvas.width - scaledWidth) / 2;
			const offsetY = (canvas.height - scaledHeight) / 2;

			ctx.clearRect(0, 0, canvas.width, canvas.height);
			ctx.drawImage(image, offsetX, offsetY, scaledWidth, scaledHeight);

			// Draw colored rectangles for the areas
			ctx.globalCompositeOperation = 'source-atop';
			for (let i = 0; i < areas.length; i++) {
				if (!areas[i])
					continue;

				const { x, y, width, height } = areas[i];
				const color = colors[i % colors.length];

				const scaledX = x * scaleRatio + offsetX;
				const scaledY = y * scaleRatio + offsetY;
				const scaledWidth = width * scaleRatio;
				const scaledHeight = height * scaleRatio;

				ctx.fillStyle = color;
				ctx.fillRect(scaledX, scaledY, scaledWidth, scaledHeight);
			}
			ctx.globalCompositeOperation = 'source-over';
			mutex = false;
		};
	}

	// Function to resize the canvas to fit its container
	function resizeCanvas() {
		const canvas = canvasRef.current;
		const canvasContainer = canvasContainerRef.current;

		canvas.width = canvasContainer.offsetWidth;
		canvas.height = canvasContainer.offsetHeight;
		
		loadCurrentImage();
	}

	/** TODO optimize by not calling on currentImageIndex because this doesn't need to be done
	 * everytime (the reality is that it's still very fast and doing it another way causes the 
	 * colors array to be empty)
	*/
	useEffect(() => {
		//console.log("Resizing!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!S");
		window.addEventListener("resize", resizeCanvas);
		resizeCanvas();
	}, [data, currentImageIndex]);

	return (
		<Card className={classes.card}>
			<Typography className={classes.cardTitle} variant="h5" component="h5">Time spent in average per section</Typography>
			<div id='canvasContainer'  className={classes.canvasContainer} ref={canvasContainerRef}>
				<canvas id="myCanvas" className={classes.canvas} ref={canvasRef}></canvas>
			</div>
			<Box className={classes.modalActions}>
				<Button
					variant="outlined"
					color="secondary"
					className={classes.modalButton}
					onClick={loadPreviousImage}
					disabled={currentImageIndex <= 0}
				>Previous</Button>
				<Typography className={classes.planeName} variant="h5" component="h5">{PlaneIDs[currentImageIndex]}</Typography>
				<Button
					variant="outlined"
					color="secondary"
					className={classes.modalButton}
					disabled={currentImageIndex >= imageData.length - 1}
					onClick={loadNextImage}
				>Next</Button>
			</Box>
		</Card>
	);
};

export default Heatmap;