/**
 * External dependencies.
 */
import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';


/**
 * Internal dependencies.
 */
import AvatarImage from 'assets/images/avatar.jpg';
import ModalEditUser from 'components/modal-edit-user/modal-edit-user'
import { getCookie, getProfileData, logout, requireUserID, setCookie } from 'MyUtils';


const useStyles = makeStyles(theme => createStyles({
	profile: {
		'& > button': {
			padding: '0',
			minWidth: '0',
		}
	},
	profileMenu: {
		'& .MuiPopover-paper': {
			width: '255px',
			maxWidth: '255px',
			boxShadow: '-8px -16px 24px rgba(227, 230, 236, 0.5), 16px 8px 24px rgba(227, 230, 236, 0.5)',
			borderRadius: '0px 0px 32px 32px',
			transform: 'translate(-17px, 60px) !important',
		},
		'& .MuiMenuItem-root': {
			width: '100%',
			fontFamily: 'Poppins, sans-serif',
			fontWeight: '600',
			fontSize: '18px',
			letterSpacing: '0.5px',
			padding: '17px 36px !important',
			'& + li': {
				paddingTop: '10px',
				borderTop: '1px solid #D9DBE9',
			}
		}
	}
}));

const WidgetProfile = ( { modifier } ) => {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = useState(null);
	const [ openInnerModal, setOpenInnerModal ] = useState(false);
	const [ avatar, setAvatar ] = useState(require("assets/images/ico-person.svg"));
	const open = Boolean(anchorEl);
	
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(async () => {
		let srcPath = getCookie("avatar");
		if (!srcPath) {
			const data = await getProfileData(requireUserID(false));
			srcPath = data.picture;
			setCookie("avatar", srcPath, 30);
		}
		setAvatar(srcPath);
	}, []);

	return (
		<Box className={classes.profile + ' ' + modifier}>
			<Button
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={(e) => setAnchorEl(e.currentTarget)}
			>
				<Avatar 
					alt="Avatar Image" 
					src={avatar} 
				/>
			</Button>

			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				className={classes.profileMenu}
			>
				<MenuItem onClick={(e) => { setOpenInnerModal(!openInnerModal) }}>Edit profile</MenuItem>

				<MenuItem onClick={() => {
					logout();
					}}>
					Sign out
				</MenuItem>
			</Menu>

			<Modal
				open={openInnerModal}
				onClose={(e) => { setOpenInnerModal(false) }}
			>
				<div><ModalEditUser userId={requireUserID(true)} reload={false} onClose={(e) => { setOpenInnerModal(false) }}/></div>
			</Modal>

		</Box>
	);
};

export default WidgetProfile;