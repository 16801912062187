/**
 * External dependencies.
 */
import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import Collapse from '@mui/material/Collapse';


/**
 * Internal dependencies.
 */
import User from 'components/user/user';

import { getAppMetadataValue, getMyUsers, requireUserID, requireAuthToken, delay } from 'MyUtils';

const useStyles = makeStyles(theme => createStyles({
	modal: {
		width: '500px',
		maxHeight: 'calc(100vh - 60px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		background: '#fff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '4px 8px 16px rgba(17, 17, 17, 0.04)',
		borderRadius: '16px',
		padding: '30px',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 60px)'
		}
	},
	modalHead: {
		position: 'relative',
		padding: '3px 0',
		margin: '0 0 49px',
	},
	modalBody: {
		position: 'relative',
		padding: '0 10px',
		maxHeight: '392px',
		overflowY: 'auto',
		overflowX: 'hidden',
		[theme.breakpoints.down('xs')]: {
			padding: '0',
			maxHeight: '193px',
		},
		'&::-webkit-scrollbar': {
			width: '6px'
		},
		'&::-webkit-scrollbar-track': {
			width: '6px',
			background: '#E6E9EF',
			borderRadius: '20px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '20px',
			background: '#808191',
		}
	},
	modalTitle: {
		fontWeight: '600',
	},
	modalClose: {
		position: 'absolute',
		top: '50%',
		right: '-10px',
		transform: 'translateY(-50%)',
		border: 'none',
		background: '#E6E9EF',
		width: '48px',
		height: '48px',
		borderRadius: '50%',
		'& i': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		}
	},
	modalActions: {
		margin: '35px -30px 0',
		padding: '27px 24px 0',
		borderTop: '1px solid #E6E9EF',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			display: 'block',

		}
	},
	modalButton: {
		flex: '0 0 calc(50% - 30px)',
		maxWidth: '50%',
		minWidth: '218px',
		margin: '0 !important',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			width: '100%',
			minWidth: '0',
		},
		'& + .MuiButtonBase-root': {
			[theme.breakpoints.down('xs')]: {
				marginTop: '20px !important'
			}
		}
	}
}));

const users = [
	{
		avatar: require('assets/images/av5.jpg'),
		name: "Tú",
		checked: false,
		admin: true
	},
	{
		avatar: require('assets/images/av2.jpg'),
		name: "Susana Suárez Sánchez",
		checked: false,
		admin: false
	},
	{
		avatar: require('assets/images/av3.jpg'),
		name: "Adrián Álvarez Pérez",
		checked: true,
		admin: false
	},
	{
		avatar: require('assets/images/av1.jpg'),
		name: "Miguel Martínez González",
		checked: false,
		admin: false
	},
	{
		avatar: "",
		name: "Sara García",
		checked: false,
		admin: false
	},
	{
		avatar: require('assets/images/av4.jpg'),
		name: "Santiago Rodríguez",
		checked: true,
		admin: false
	},
	{
		avatar: "",
		name: "Marina Ortega",
		checked: false,
		admin: false
	},
	{
		avatar: require('assets/images/av2.jpg'),
		name: "Susana Suárez Sánchez",
		checked: false,
		admin: false
	},
	{
		avatar: require('assets/images/av3.jpg'),
		name: "Adrián Álvarez Pérez",
		checked: true,
		admin: false
	},
	{
		avatar: require('assets/images/av1.jpg'),
		name: "Miguel Martínez González",
		checked: false,
		admin: false
	},
	{
		avatar: "",
		name: "Sara García",
		checked: false,
		admin: false
	},
	{
		avatar: require('assets/images/av4.jpg'),
		name: "Santiago Rodríguez",
		checked: true,
		admin: false
	},
	{
		avatar: "",
		name: "Marina Ortega",
		checked: false,
		admin: false
	}
]

const ModalGuests = ({ onClose, modifier, roomId }) => {
	const classes = useStyles();
	const [modifiedAccess, setModifiedAccess] = useState([]);
	const [progress, setProgress] = useState(0);
	const [disabled, setDisabled] = useState(false);

	const [users, setUsers] = useState(() => {
		let aux = [];
		for (let i = 0; i < 6; i++) {
			aux.push({
				avatar: "loading.jpg",
				name: "loading",
				checked: true,
				admin: true,
				skeleton: true,
				rooms: []
			});
		}
		return aux;
	});


	useEffect(() => {
		async function fetchUsers() {
			const data = await getMyUsers(false, false, true);

			//console.log(data)

			if (data.length > 0) {
				let auxUsers = [];
				setUsers([]);
				data.forEach(element => {
					//console.log(element);
					//const isInRoom = Boolean(element.app_metadata.find((currentValue, index, arr) => currentValue.Key === "Creator").Value);
					let rooms = getAppMetadataValue(element, "Rooms");
					if (rooms === "") {
						rooms = []
					}
					const isInRoom = rooms.includes(roomId)
					const isAdmin = element.user_id === requireUserID(true);

					//console.log(`isInRoom ${isInRoom}, isAdmin ${isAdmin}`);
					const userObject = {
						name: element.username,
						avatar: element.picture,
						admin: isAdmin,
						checked: isInRoom,
						user_id: element.user_id,
						skeleton: false,
						rooms: rooms
					};

					auxUsers.push(userObject);
				});
				auxUsers.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase())
				setUsers(auxUsers);
			}
		};

		fetchUsers();
	}, [])

	const handleChange = e => {
		const { value, checked } = e.target;
		const filteredArray = modifiedAccess.filter(x => x.userId !== value);

		if (filteredArray.length == modifiedAccess.length) {
			setModifiedAccess(prev => [...prev, { userId: value, hasAccess: checked }]);
			//console.log("added to list");
		} else {
			setModifiedAccess(filteredArray);
			//console.log("deleted from list");
		}
		//console.log(modifiedAccess);
	}

	return (
		<Box className={classes.modal + ' ' + modifier}>
			<Box className={classes.modalHead}>
				<button className={classes.modalClose} onClick={onClose} disabled={disabled}>
					<i className="ico-x"></i>
				</button>

				<Typography
					variant="h4"
					component="h4"
					className={classes.modalTitle}
				>Guests</Typography>
			</Box>

			<Box className={classes.modalBody}>
				{users?.map((user, index) => {
					return (
						<User
							key={index}
							name={user.name}
							avatar={user.avatar}
							checked={user.checked}
							admin={user.admin}
							userId={user.user_id}
							changeFunction={handleChange}
							skeleton={user.skeleton}
							rooms={user.rooms}
						/>
					)
				})}
			</Box>
			<Collapse in={disabled}><LinearProgress variant="determinate" value={progress} /></Collapse>

			<Box className={classes.modalActions}>
				<Button
					variant="outlined"
					color="secondary"
					className={classes.modalButton}
					disabled={disabled}
					onClick={onClose}
				>Cancel </Button>

				<Button
					variant="contained"
					color="secondary"
					className={classes.modalButton}
					disabled={disabled}
					onClick={(e) => {
						submitChanges();

						async function submitChanges() {
							setDisabled(true)
							const authToken = await requireAuthToken(false, true);

							for (let i = 0; i < modifiedAccess.length; i++) {
								const change = modifiedAccess[i];
								
								setProgress(100 / modifiedAccess.length * (i + 1));
								const user = users.find(elem => elem.user_id == change.userId);
								let RoomsMetadata = user?.rooms;
								if (RoomsMetadata == undefined) {
									RoomsMetadata = [];
								}

								if (change.hasAccess) {
									RoomsMetadata.push(roomId);
								} else {
									const index = RoomsMetadata.indexOf(roomId);
									if (index > -1) {
										RoomsMetadata.splice(index, 1);
									}
								}

								// Try to modify metadata with counter to avoid timeouts
								let response;
								let counter = 0;
								do {
									if (counter++ >= 10) {
										setDisabled(false);
										throw new Error(`HTTP error! Exceeded count, status: ${response.status}`);
									}

									response = await fetch(`${process.env.REACT_APP_ENDPOINT}/login/user/${change.userId}`, {
									method: "POST",
									body: JSON.stringify({
										App_Metadata: { Rooms: RoomsMetadata }
									}),
									headers: {
										"Content-type": "application/json; charset=UTF-8",
										"Authorization": `Bearer ${authToken}`
									}
								});

									if (response.status == 429) {
										// Espera activa con efectos de carga
										//console.log("Waiting " + counter);
										const totalDelay = 4000;
										const firstDelay = Math.floor(Math.random() * 3000);
										await delay(firstDelay);
										setProgress(100 / modifiedAccess.length * (i + 1 + counter * 0.1));
										await delay(totalDelay - firstDelay);
										setProgress(100 / modifiedAccess.length * (i + 1 + counter * 0.2));
									}
								} while (response.status == 429);

								//console.log(`${change.userId} exited dowhile`);

								if (!response.ok) {
									setDisabled(false);
									throw new Error(`HTTP error! status: ${response.status}`);
								}

								const data = await response.json();

								//console.log(data);

								if ("Error" in data) {
									setDisabled(false);
									throw new Error(`Error creating room: ${data.Error.Message}`);
								}
							}
							setDisabled(false);
							onClose();
						}
					}}
				>Save</Button>
			</Box>
		</Box>
	);
};

export default ModalGuests;