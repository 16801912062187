/**
 * External dependencies.
 */
import React, {useState, useEffect} from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';

/**
 * Internal dependencies.
 */
import WidgetCircle from 'components/widget-circle/widget-circle';
import { getProfileData, registerUser, requireUserID, requireAuthToken, getAppMetadataValue, generateFakeEmail, getRoles } from 'MyUtils';
import { Skeleton } from '@mui/material';

const useStyles = makeStyles(theme => createStyles({
	modal: {
		width: '500px',
		maxHeight: 'calc(100vh - 60px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		background: '#fff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '4px 8px 16px rgba(17, 17, 17, 0.04)',
		borderRadius: '16px',
		padding: '30px',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 60px)'
		}
	},
	modalHead: {
		position: 'relative',
		padding: '3px 0',
		margin: '0 0 42px',
	},
	modalTitle: {
		fontWeight: '600',
	},
	modalClose: {
		position: 'absolute',
		top: '50%',
		right: '-10px',
		transform: 'translateY(-50%)',
		border: 'none',
		background: '#E6E9EF',
		width: '48px',
		height: '48px',
		borderRadius: '50%',
		'& i': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		}
	},
	modalField: {
		marginBottom: '21px',
		'& input': {
			fontSize: '18px',
			fontWeight: '500',
			letterSpacing: '0.5px',
			height: '44px',
			border: 'none',
			background: '#F0F0F3',
			fontFamily: 'Poppins, sans-serif',
			'&::-webkit-input-placeholder': {
				color: '#858585',
				oapcity: '1',
				fontWeight: '500'
			},
			'&::-moz-placeholder': {
				color: '#858585',
				oapcity: '1',
				fontWeight: '500'
			},
			'&:-moz-placeholder': {
				color: '#858585',
				oapcity: '1',
				fontWeight: '500'
			},
			'&:-ms-input-placeholder': {
				color: '#858585',
				oapcity: '1',
				fontWeight: '500'
			},
		}
	},
	modalCircle: {
		width: '200px',
		height: '200px',
		borderWidth: '2px',
		margin: '0 auto 29px',
		[theme.breakpoints.down('xs')]: {
			margin: '0 auto 29px'
		},
		'& .MuiButtonBase-root': {
			minWidth: '134px',
			fontSize: '11px',
			borderRadius: '9px',
			padding: '8px 10px',
			marginTop: '3px',
		}
	},
	modalActions: {
		margin: '27px -30px 0',
		padding: '25px 24px 0',
		borderTop: '1px solid #E6E9EF',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			display: 'block',

		}
	},
	modalButton: {
		flex: '0 0 calc(50% - 30px)',
		maxWidth: '50%',
		minWidth: '218px',
		margin: '0 !important',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			width: '100%',
			minWidth: '0',
		},
		'& + .MuiButtonBase-root': {
			[theme.breakpoints.down('xs')]: {
				marginTop: '20px !important'
			}
		}
	},
	modalButtonDisabled: {
		opacity: '.3'
	},
	modalText: {
		fontSize: '17px',
		fontWeight: '400',
		lineHeight: '1.41',
		padding: '3px 12px 0',
		'& span': {
			fontWeight: '600',
		}
	},
	errorMessage: {
		color: '#f50057'
	},
	skeletonStyle: {
		minWidth: '100% !important'
	}
}));

async function submitCredentials(e, setErrorMessage, setDisabled, createAdmin) {
	e.preventDefault()
	setErrorMessage("");
	setDisabled(true);

	const creatorUserID = await requireUserID(true);
	const creatorProfile = await getProfileData(creatorUserID);
	const metadata = getAppMetadataValue(creatorProfile, "Creator");

	if (!metadata) {
		setErrorMessage(`Your account does not have the required "Creator" metadata. This usually happens with accounts created manually outside Circles. Please contact Extendra for assistance.`)
		throw new Error(`"Creator" metadata missing from currently logged-in user's profile.`);
	}

	try {
		await registerUser(generateFakeEmail(), document.getElementById("name-field").value, document.getElementById("password-field").value, metadata, createAdmin, false);
	} catch (error) {
		if (error.message == "The user already exists.")
			setErrorMessage("The email provided is in use already.");
		else
			setErrorMessage(error.message);
			setDisabled(false);
		return;
	}

	alert("Client user registered successfully")
	window.location.reload();
}

const ModalNewUser = ({ onClose, modifier }) => {
	const classes = useStyles();
	const [ errorMessage, setErrorMessage ] = useState("");
	const [ disabled, setDisabled ] = useState(false);
	const [ isSuperadmin, setIsSuperadmin ] = useState(false);
	const [ skeleton, setSkeleton ] = useState(true);

	useEffect(() => {
		async function requestRoles() {
			const authToken = await requireAuthToken(true, false);
			const userID = await requireUserID(true);
			const dataRoles = await getRoles(userID);

			const superadminRole = dataRoles.Roles.find(element => element.id == process.env.REACT_APP_AUTH0_SUPERADMIN_ROLEID);

			setIsSuperadmin(Boolean(superadminRole));
			setSkeleton(false);
		};

		requestRoles();
	}, []);


	return (
		<Box className={classes.modal + ' ' + modifier}>
			<Box className={classes.modalHead}>
				<button className={classes.modalClose} onClick={onClose}>
					<i className="ico-x"></i>
				</button>

				{skeleton ? <Skeleton animation='wave' variant="text"><Typography
					variant="h4"
					component="h4"
				>New {isSuperadmin ? "admin" : "client"} user</Typography></Skeleton> : 
				<Typography
					variant="h4"
					component="h4"
					className={classes.modalTitle}
				>New {isSuperadmin ? "admin" : "client"} user</Typography>
				}
			</Box>

			<Box className={classes.modalBody}>
				{skeleton ? <Skeleton animation='wave' variant="text" className={classes.skeletonStyle}><Input placeholder="Username" className={classes.modalField} id="name-field" disabled={disabled} onKeyDown={e => {
							if (e.keyCode == 13 /*enter*/)
								document.getElementById("password-field").focus();
						}} /></Skeleton> : 
						<Input placeholder="Username" className={classes.modalField} id="name-field" disabled={disabled} onKeyDown={e => {
							if (e.keyCode == 13 /*enter*/)
								document.getElementById("password-field").focus();
						}} />}

				{skeleton ? <Skeleton animation='wave' variant="text" className={classes.skeletonStyle}><Input placeholder="Password" className={classes.modalField} disabled={disabled} id="password-field" onKeyDown={(e) => {
							if (e.keyCode == 13 /*enter*/)
							submitCredentials(e, setErrorMessage, setDisabled, isSuperadmin);
						}}/></Skeleton> : 
						<Input placeholder="Password" className={classes.modalField} disabled={disabled} id="password-field" onKeyDown={(e) => {
							if (e.keyCode == 13 /*enter*/)
							submitCredentials(e, setErrorMessage, setDisabled, isSuperadmin);
						}}/>}

				<Typography
					variant="h4"
					component="h4"
					className={classes.modalText}
				>
				</Typography>

				<Typography variant="subtitle1" className={classes.errorMessage}>{errorMessage}</Typography>
			</Box>

			<Box className={classes.modalActions}>
				<Button
					variant="outlined"
					color="secondary"
					className={classes.modalButton}
					onClick={onClose}
				>Cancel</Button>

				<Button
					variant="contained"
					color="secondary"
					className={classes.modalButton /* + ' ' + classes.modalButtonDisabled */}
					disabled={disabled || skeleton}
					onClick={(e) => {
						submitCredentials(e, setErrorMessage, setDisabled, isSuperadmin);
					}}
				>Create user{/* Enviar invitación */}</Button>
			</Box>
		</Box>
	);
};

export default ModalNewUser;