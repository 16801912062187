/**
 * External dependencies.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { Skeleton } from '@mui/material';

const useStyles = makeStyles(theme => createStyles({
	user: {
		position: 'relative',
		marginBottom: '16px',
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			marginBottom: '15px',
		}
	},
	userActions: {
		position: 'absolute',
		top: '50%',
		right: '11px',
		transform: 'translateY(-50%)',
		[theme.breakpoints.down('xs')]: {
			right: '0',
		}
	},
	userAvatar: {
		marginRight: '16px',
		width: '40px',
		height: '40px',
		fontSize: '20px',
		backgroundColor: '#e6e9ef',
		color: '#808191',
		[theme.breakpoints.down('xs')]: {
			marginRight: '8px',
			fontSize: '12px',
			width: '24px',
			height: '24px'
		}
	}
}));

const User = ( { avatar, name, checked, admin, userId, changeFunction, skeleton } ) => {
	const classes = useStyles();
	const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

	return (
		<Box className={classes.user}>
			{(!avatar.length || skeleton) ? (
				<Skeleton animation='wave' variant="circular" className={classes.userAvatar}>
				<Avatar className={classes.userAvatar}>
					{"hola"}
				</Avatar>
				</Skeleton>
			) : (
				<Avatar
					className={classes.userAvatar}
					alt={name}
					src={avatar}
				/>
			)}

			{skeleton? 	<Skeleton animation='wave' variant="text"><Typography variant="h5" component="h5">{name}</Typography></Skeleton> : <Typography variant="h5" component="h5">{name}</Typography>}

			<Box className={classes.userActions}>
				{skeleton? <Skeleton animation='wave' variant="rectangular" style={{width: "24px", height: "24px"}}><Checkbox {...label} defaultChecked={checked || admin} value={userId} disabled={admin} onChange={changeFunction}/></Skeleton> : <Checkbox {...label} defaultChecked={checked || admin} value={userId} disabled={admin} onChange={changeFunction}/>}
			</Box>	

			{/* admin ? (
				<Box className={classes.userActions}>
					<Checkbox {...label} defaultChecked disabled />
				</Box>	
			) : (
				<Box className={classes.userActions}>
					<Checkbox {...label} checked={checked} />
				</Box>	
			)*/ }
		</Box>
	);
};

export default User;