/**
 * External dependencies.
 */
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Modal from '@mui/material/Modal';
import { Skeleton } from '@mui/material';

import ModalEditUser from 'components/modal-edit-user/modal-edit-user';

const useStyles = makeStyles(theme => createStyles({
	user: {
		position: 'relative',
		marginBottom: '16px',
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			marginBottom: '15px',
		}
	},
	userActions: {
		position: 'absolute',
		top: '50%',
		right: '11px',
		transform: 'translateY(-50%)',
		[theme.breakpoints.down('xs')]: {
			right: '0',
		}
	},
	userAvatar: {
		marginRight: '16px',
		width: '40px',
		height: '40px',
		fontSize: '20px',
		backgroundColor: '#e6e9ef',
		color: '#808191',
		[theme.breakpoints.down('xs')]: {
			marginRight: '8px',
			fontSize: '12px',
			width: '24px',
			height: '24px'
		}
	},
	userButton: {
		position: 'absolute',
		top: '50%',
		right: '40px',
		transform: 'translateY(-50%)',
		[theme.breakpoints.down('md')]: {
			right: '20px',
		},
		[theme.breakpoints.down('xs')]: {
			transform: 'translateY(0)',
			top: '-4px',
			right: '0',
		},
		fontSize: '18px',
		minWidth: '180px',
		color: '#00CCEE',
		letterSpacing: '1px',
		background: 'none !important',
		borderRadius: '16px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0',
			minWidth: '0',
			padding: '0',
		},
		'&:hover': {
			boxShadow: '-4px -4px 16px rgba(227, 230, 236, 0.5), 8px 4px 16px rgba(227, 230, 236, 0.5)'
		},
		'& i': {
			display: 'none',
			[theme.breakpoints.down('sm')]: {
				display: 'inline-block',
			}
		}
	}
}));

const UserClient = ( { avatar, name, userId, skeleton } ) => {
	const classes = useStyles();
	const [openModal, setOpenModal] = useState(false);

	return (
		<Box className={classes.user}>
			{(!avatar.length || skeleton) ? (
				<Skeleton animation='wave' variant="circular" className={classes.userAvatar}>
				<Avatar className={classes.userAvatar}>
					{"hola"}
				</Avatar>
				</Skeleton>
			) : (
				<Avatar
					className={classes.userAvatar}
					alt={name}
					src={avatar}
				/>
			)}

			{skeleton? 	<Skeleton animation='wave' variant="text"><Typography variant="h5" component="h5">{name}</Typography></Skeleton> : <Typography variant="h5" component="h5">{name}</Typography>}
			{skeleton? 	(
				<Skeleton animation='wave' variant="button"><Button 
					variant="text" 
					className={classes.userButton}
					onClick={(e) => { setOpenModal(!openModal) }}
				>
					Edit user
					<i className="ico-pen"></i>
				</Button></Skeleton>
			) : (
				<Button 
					variant="text" 
					className={classes.userButton}
					onClick={(e) => { setOpenModal(!openModal) }}
				>
					Edit user
					<i className="ico-pen"></i>
				</Button>
			)}
			<Modal
				open={openModal}
				onClose={(e) => { setOpenModal(false) }}
			>
				<div><ModalEditUser userId={userId} reload={true} onClose={(e) => { setOpenModal(false) }} /></div>
			</Modal>
		</Box>	
	);
};

export default UserClient;