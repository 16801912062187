/**
 * External dependencies.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

/**
 * Internal dependencies.
 */
import WidgetLoad from 'components/widget-load/widget-load';

const useStyles = makeStyles(theme => createStyles({
	modal: {
		width: '500px',
		height: '580px',
		maxHeight: 'calc(100vh - 60px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		background: '#fff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '4px 8px 16px rgba(17, 17, 17, 0.04)',
		borderRadius: '16px',
		padding: '30px 36px',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 60px)'
		}
	},
	modalHead: {
		position: 'relative',
		padding: '3px 0',
		margin: '0 -6px 41px'
	},
	modalTitle: {
		fontWeight: '600',
	},
	modalClose: {
		position: 'absolute',
		top: '50%',
		right: '-10px',
		transform: 'translateY(-50%)',
		border: 'none',
		background: '#E6E9EF',
		width: '48px',
		height: '48px',
		borderRadius: '50%',
		'& i': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		}
	},
	modalCircle: {
		position: 'absolute',
		top: '-14px',
		left: '20px',
		width: '200px',
		height: '200px',
		borderWidth: '2px',
		[theme.breakpoints.down('xs')]: {
			position: 'relative',
			top: '0',
			left: '0',
			margin: '0 auto 20px'
		},
		'& .MuiButtonBase-root': {
			minWidth: '134px',
			fontSize: '11px',
			borderRadius: '9px',
			padding: '8px 10px',
			marginTop: '3px',
		}
	},
	modalActions: {
		position: 'absolute',
		bottom: '29px',
		left: '0',
		width: '100%',
		padding: '27px 26px 0',
		borderTop: '1px solid #E6E9EF',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			padding: '27px 30px 0',
			display: 'block',
		}
	},
	modalButton: {
		flex: '0 0 calc(50% - 30px)',
		maxWidth: '50%',
		minWidth: '218px',
		margin: '0 !important',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			width: '100%',
			minWidth: '0',
		},
		'& + .MuiButtonBase-root': {
			[theme.breakpoints.down('xs')]: {
				marginTop: '20px !important'
			}
		}
	},
	modalButtonDisabled: {
		opacity: '.3',
	},
	modalContent: {
		position: 'relative',
		padding: '0 0 0 255px',
		margin: '0 0 93px',
		[theme.breakpoints.down('xs')]: {
			padding: '0',
			margin: '0 0 50px',
		}
	},
	modalText: {
		fontSize: '17px',
		lineHeight: '1.65',
		'& strong': {
			fontWeight: '700'
		}
	},
	modalSubtitle: {
		fontSize: '16px',
		fontWeight: '500',
		color: '#808191',
		marginBottom: '21px',
		'& strong': {
			fontWeight: '700'
		}
	},
	modalWidget: {
		margin: '0 -6px 26px',
		height: '237px',
		'& > div': {
			left: '20px',
			right: '1px',
			marginTop: '17px',
			[theme.breakpoints.down('xs')]: {
				left: '10px',
				right: '10px',
			}
		}
	}
}));

const ModalMediaLoading = ( { onClose, modifier } ) => {
	const classes = useStyles();

	return (
		<Box className={classes.modal + ' ' + modifier}>
			<Box className={classes.modalHead}>
				<button className={classes.modalClose} onClick={onClose}>
					<i className="ico-x"></i>
				</button>

				<Typography 
					variant="h4" 
					component="h4"
					className={classes.modalTitle}
				>Subir archivo</Typography>
			</Box>

			<WidgetLoad 
				modifier={classes.modalWidget}
				status="loading"
				progress={25}
			/>

			<Box className={classes.modalActions}>
				<Button 
					variant="outlined" 
					color="secondary"
					className={classes.modalButton + ' ' + classes.modalButtonDisabled }
					onClick={onClose}
				>Cancelar</Button>
				
				<Button 
					variant="contained" 
					color="secondary"
					className={classes.modalButton + ' ' + classes.modalButtonDisabled }
					onClick={onClose}
				>Añadir archivo</Button>
			</Box>
		</Box>
	);
};

export default ModalMediaLoading;