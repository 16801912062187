/**
 * External dependencies.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

/**
 * Internal dependencies.
 */
import LayoutBase from 'layouts/layout-base/layout-base';
import FormSignupAltValid from 'components/form-signup-alt-valid/form-signup-alt-valid';

const useStyles = makeStyles(theme => createStyles({
	section: {
		position: 'relative',
		zIndex: '10',
		height: 'calc(100vh - 64px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '100px 0 34px',
		color: '#fff',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			display: 'block',
			padding: '150px 0 70px',
		},
	},
	sectionContent: {
		[theme.breakpoints.down('md')]: {
			margin: '0 0 40px'
		}
	}
}));

const SignupAltValid = () => {
	const classes = useStyles();

	return (
		<LayoutBase>
			<Box className={classes.section}>
				<Box>
					<Container maxWidth="xl">
						<Grid container spacing={2} alignItems="center">
							<Grid item md={6}>
								<Box className={classes.sectionContent}>
									<Typography variant="h1" component="h1">
										Gestiona tu mundo virtual
									</Typography>

									<Typography component="p">
										circles es una herramienta de Extendra para equipos que necesitan un espacio colaborativo en el que crear eventos, formaciones o showrooms virtuales.
									</Typography>
								</Box>
							</Grid>

							<Grid item md={6}>
								<FormSignupAltValid />
							</Grid>
						</Grid>
					</Container>
				</Box>
			</Box>
		</LayoutBase>
	);
};

export default SignupAltValid;
