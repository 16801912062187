/**
 * External dependencies.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

/**
 * Internal dependencies.
 */
import boxImage from 'assets/images/tunel-small.png';

const useStyles = makeStyles(theme => createStyles({
	box: {
		position: 'relative',
		width: '380px',
		height: '380px',
		borderRadius: '50%',
		border: '5px dashed #fff',
		background: 'linear-gradient(#fff,#fff) padding-box , linear-gradient(to right, #0C55ED, #00CCEE 49%) border-box',
		[theme.breakpoints.down('xs')]: {
			width: '300px',
			height: '300px',
		},
		[theme.breakpoints.down('xs')]: {
			width: '214px',
			height: '214px',
			borderWidth: '2px',

		}
	},
	boxButton: {
		minWidth: '234px',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		padding: '10px 5px',
		fontSize: '22px',
		margin: '6px 0 0',
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px',
			minWidth: '180px',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
			minWidth: '132px',
			padding: '7px 5px',
			margin: '3px 0 0',
		}
	}
}));

const WidgetCircle = ( { modifier, url, onClick, buttonText, children } ) => {
	const classes = useStyles();

	return (
		<Box className={classes.box + ' ' + modifier}>
			<img src={boxImage} alt="" />

			<Button
				className={classes.boxButton} 
				variant="contained" 
				color="secondary"
				component={Link}
				to={url}
				onClick={onClick}
			>
				{buttonText}
				{children}
			</Button>
		</Box>
	);
};

export default WidgetCircle;