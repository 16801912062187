/**
 * Exernal styles
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';

/**
 * Internal styles
 */
import 'styles/style.scss'; 
import App from './app';
import theme from './theme';

setChonkyDefaults({ iconComponent: ChonkyIconFA });

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<App />
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);